import React from 'react'
import CreateHidePaymentMethods from './createHidePaymentMethods'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function HidePaymentMethodsItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateHidePaymentMethods {...props} id={id} />
}

export default HidePaymentMethodsItem;